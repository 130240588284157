
.initial-content-wrapper {
    max-width: 1600px;
    background-color: white;
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    flex-shrink: 0;
}

.intial-content-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 89%;
}

.initial-content-text-box {
    position: absolute;
    left: 0px;
    background-color: white;
    width: 50%;
    height: 300px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.472);
    border-radius: 0px 10px 10px 0px;
}

.initial-content-text-box p {
    font-size: 1.3rem;
    margin-left: 20px;
}

.initial-content-text-box h2 {
    font-size: 3rem;
    margin-left: 20px;
}

.link-button {
    text-decoration: none;
    color: black;
    font-size: 2rem;
    font-weight: 500;
    border-bottom: 2px solid black;
}