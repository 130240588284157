
.App {
    height: auto;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: -1;
    
}

.background-paralax {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-image: url(https://res.cloudinary.com/drlkgoter/image/upload/v1670444831/360_F_283892714_7vR275IsKMuEgDjvqdkHuDaPzbH78RH6_b9w71f.jpg);
}