.contact-page {
    margin-top: 50px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-page-wrapper .text-container {
    margin-left: 2rem;
}

.sub-page-title-wrapper {
    width: 100%;
    max-width: 1600px;
    margin-left: 1rem;
}

.contact-page-wrapper {
    max-width: 1600px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.contact-form-container {
    width: 47%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-contact-form-container {
    width: 100%;
    height: auto;
    max-height: 95%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inner-contact-form-container h2 {
    font-size: 3rem;
    margin: 0.5rem 0 .5rem 1rem;
    letter-spacing: 2px;
    font-weight: 600;
}

.inner-contact-form-container p {
    font-size: 2rem;
    opacity: 0.7;
    width: 100%;
    letter-spacing: 2px;
}

.inner-contact-form-container .icon-container {
    margin-left: 1rem;
}

/* mobile */

@media only screen and (orientation: portrait) {
    
    
    .contact-page-wrapper {
        height: auto;
        justify-content: unset;
        flex-direction: column;
        flex-shrink: 0;
        
    }
    
    .contact-form-container {
        width: 90%;
        height: auto;
        flex-shrink: 0;
    }
    .contact-page-wrapper .text-container {
        margin-left: 0rem;
        flex-shrink: 0;
    }
}