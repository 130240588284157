
.splash-screen-container {
    height: 100vh;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.splash-screen-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.title-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.342);
    flex-shrink: 0;
    flex-direction: column;
    z-index: 2;
    
}

.inner-title-container {
    width: calc(100% - 10px);
    max-width: 600px;
    border-radius: 10px;
}

.title-container h1 {
    font-size: 5rem;
    color: rgb(0, 0, 0);
    margin-bottom: 0;
    letter-spacing: 2px;
    font-weight: 400;
    text-align: center;
}

.title-container h3 {
    font-size: 2rem;
    color: rgb(0, 0, 0);
    letter-spacing: 2px;
    text-align: center;
    font-weight: 300;
}

@media only screen and (orientation: portrait) {
    .title-container h1 {
        font-size: 3rem;
    }
    .title-container h3 {
        font-size: 1.4rem;
    }
}
