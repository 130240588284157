.image-component-container {
    display: inline-block;
    position: relative;
    min-height: 100px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-component-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}