.menu-outer-container {
    position: fixed;
    top: 90px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.478);
}
.menu-inner-container {
    width: 500px;
    height: 700px;
    background-color: white;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    padding: 0 1rem;
    position: relative;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.414);
}

.title-menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-menu-wrapper svg {
    cursor: pointer;
    width: 55px;
    height: 55px;
}

.title-menu-wrapper svg:hover {
    opacity: 0.7;
}

.title-menu-wrapper h2 {
    font-size: 1.8rem;
}

.rating-wrapper {
    border-left: solid 10px black;
    padding: 5px;
}

.rating-wrapper svg {
    cursor: pointer;
}

.menu-inner-container input {
    height: 60px;
    border-left: solid 10px black;
    border-top: none;
    border-right: none;
    border-bottom: none;
    padding: 5px;
    font-size: 1.5rem;
    background-color: none;
    border-radius: 0px;
}

.menu-inner-container textarea {
    height: 300px;
    font-size: 1.5rem;
    font-family: "Comfortaa", sans-serif;
    padding: 5px;
    border-left: solid 10px black;
    border-top: none;
    border-right: none;
    border-bottom: none;
    resize: none;
    border-radius: 0px;
}

.menu-inner-container button {
    border-left: solid 10px black;
    border-top: none;
    border-right: none;
    border-bottom: none;
    background-color: white;
    font-size: 1.5rem;
    height: 60px;
    margin-bottom: 1rem;
    cursor: pointer;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black;
}

.menu-inner-container button:hover {
    background-color: rgb(206, 206, 206);
}

@media only screen and (orientation: portrait) {
    .menu-inner-container {
        width: 100%;
        height: 100%;
    }
    .menu-inner-container textarea {
        height: 200px;
    }
    .menu-inner-container input {
        border-radius: 0px;
    }
}