
.image-container {
    width: 45%;
    max-height: 80vh;
    height: 80vh;
    
    overflow: hidden;
    position: relative;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    border-radius: 10px;
}

/* mobile */

@media only screen and (orientation: portrait) {
  .image-container {
      width: 100% !important;
      object-fit: contain;
  }
}