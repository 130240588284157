.loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--secondary-color-var);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading-wrapper {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: loadingSpin 0.5s forwards linear infinite;
}

.loading-wrapper svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@keyframes loadingSpin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}