
.error-alert-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    flex-direction: column;
}

.error-alert-container p {
    font-size: 3vh;
}

.error-alert-container button {
    width: 90%;
}

.error-alert-container button:hover {
    background-color: rgb(224, 224, 224);
}