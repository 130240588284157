.gallery-outer-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 50px;
    min-height: 100vh;
    position: relative;
    flex-direction: column;
    transition: 0.1s;
    animation: fade-in 0.1s linear forwards;
}

.page-subtitle-container {
    width: 100%;
    margin-left: 1rem;
    max-width: 1600px;
}

.gallery-inner-container {
    margin-top: 20px;
    max-width: 1600px;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    width: calc(100% - 20px);
    justify-content: center;
    align-items: center;
}

.gallery-photo-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
}

.gallery-photo-container:hover {
    filter: brightness(105%);
}

.gallery-photo-container img {
    width: 98%;
    height: 98%;
    object-fit: cover;
    cursor: pointer;
}

.expanded-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f990;
}

.expanded-image-container img {
    max-width: 95%;
    max-height: 95%;
}