nav {
    position: fixed;
    top: 5px;
    left: 5px;
    height: 50px;
    width: calc(100% - 10px);
    display: flex;
    z-index: 99;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    background-color: white;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.28);
}



.top {
    background-color: var(--navigation-color-var);
}

.nav-icon-container {
    height: 40px;
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.nav-icon-container h2 {
    text-transform: capitalize;
    font-weight: 800;
}

.nav-icon-container img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: contain;
    margin-right: 5px;
}

.links-container {
    width: auto;
    height: 90px;
    display: flex;
    justify-content: space-between;

    align-items: center;
}


.nav-link:hover {
    opacity: 0.6;
    text-decoration: underline;
}



.nav-link {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 1.3rem;
    cursor: pointer;
    letter-spacing: 2px;
    margin: 0 1rem;
    font-weight: 500;
}

.mobile-nav-button {
    display: none;
}
.mobile-nav {
    display: none;
}

@media only screen and (orientation: portrait) {
    nav {
        height: 50px;
    }
    .links-container {
        display: none;
    }
    .nav-icon-container {
        margin-left: 1rem;
        width: 40px;
        height: 40px;
    }
    .mobile-nav-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        padding: 5px;
        margin-right: 1rem;
        transition: 0.2s;
        border-radius: 50%;
        cursor: pointer;
    }

    .mobile-nav-button:hover {
        scale: 1.2;
        border-radius: 5px;
    }

    .mobile-nav {
        display: flex;
        height: 100vh;
        width: 100%;
        background-repeat: repeat;
        background-image: url(https://res.cloudinary.com/drlkgoter/image/upload/v1670444831/360_F_283892714_7vR275IsKMuEgDjvqdkHuDaPzbH78RH6_b9w71f.jpg);
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 999;
    }

    .mobile-links-container {
        background-color: var(--secondary-color-var);
        height: 95%;
        width: 95%;
        border-radius: 15px;
        border: solid 4px var(--border-color-var);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .mobile-links-container .nav-link {
        color: black;
        font-size: 2rem;
        margin: 1rem 0;
    }

    .close-button {
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        justify-self: flex-end;
        cursor: pointer;
    }
    .close-button:hover {
        opacity: 0.6;
    }

    .close-button svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}