.review-tab-container {
    width: calc(100% - 1rem);
    flex-shrink: 0;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--secondary-color-var);
    padding: 0.5rem;
    border-radius: 10px;
}

.review-tab-container h3 {
    margin: 10px 0px 0px 0px;
    font-size: 1.8rem;
}

.review-tab-container .review-content {
    font-size: 1.3rem;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
}

.review-rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-rating-wrapper svg{
    width: 25px;
    height: 25px;
}

@media only screen and (orientation: portrait) {
    .review-tab-container {
        width: calc(100% - 2rem);
        padding: 1rem;
    }
}